const MONTHS_MAP = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dev',
};

const generateTimeSlots = (apiSlots) => {
  const initialState = [...apiSlots];

  if (initialState.length > 0) {
    return initialState.map((slot) => {
      const dayOfWeek = slot.DayOfWeek.slice(0, 3);
      const date = slot.CalendarDate;
      const monthNumber = date.slice(0, 2);
      const currentMonth = MONTHS_MAP[monthNumber];
      const currentDayDate = date.slice(3, 5);
      const currentFullDate = currentMonth + ' ' + currentDayDate;

      return {
        day: dayOfWeek,
        date: currentFullDate,
        flag: 'parser',
      };
    });
  }

  return apiSlots;
};

export default generateTimeSlots;
