import React, { useEffect, useState } from 'react';

import {
  BaseContainer,
  Card,
  EmptyBox,
  Flexbox,
  Header,
  ProviderPagination,
  ScreenLoader,
  SearchButton,
  WeekView,
} from './components';

import {
  getInsurances,
  getProviders,
  getStates,
  getVisitReasons,
} from './api/requests';

import './App.css';
import { Toaster } from 'react-hot-toast';
import LightBaseSelect from './components/BaseSelect/LightBaseSelect';

import { AppointmentModule } from './modules';
import InsuranceMenu from './components/InsuranceMenu/InsuranceMenu';
import { useMediaQuery } from '@mui/material';

import PaymentModule from './modules/PaymentModule/PaymentModule';
import generateTimeSlots from './helper/utils';

// const getWeek = (prev = 0, next = 4, timeZone) => {
//   const start = new Date().setDate(new Date().getDate() + prev);
//   const end = new Date().setDate(new Date().getDate() + next);

//   const timeZoneStart = toZonedTime(start, timeZone);
//   const timeZoneEnd = toZonedTime(end, timeZone);

//   const daysOfWeek = eachDayOfInterval({
//     start: timeZoneStart,
//     end: timeZoneEnd,
//   });

//   const weekdays = daysOfWeek.map((day) => {
//     const obj = {
//       day: day.toString().substr(0, 3),
//       date: day.toString().substr(4, 6),
//     };
//     return obj;
//   });

//   return weekdays;
// };

const PAGE_SIZE = 5;

const PATIENT_STATUS = [
  {
    label: 'New Patient',
    name: 'New Patient',
  },
  {
    label: 'Current Patient',
    name: 'Current Patient',
  },
];

const App = () => {
  const matchesMobile = useMediaQuery('(max-width:600px)');
  const matchesTablet = useMediaQuery('(max-width:768px)');

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(null);
  const [update, setUpdate] = useState(false);
  const [openModule, setOpenModule] = useState(false);
  const [currentSlot, setCurrentSlot] = useState({
    provider: null,
    time: null,
  });
  const [notPlan, setNotPlan] = useState(false);

  const [statesOptions, setStatesOptions] = useState([]);
  const [insurancesOptions, setInsurancesOptions] = useState([]);
  const [visitReasonsOptions, setVisitReasonssOptions] = useState([]);
  const [patientValue, setPatientValue] = useState(null);

  const [reason, setReason] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [providers, setProviders] = useState(null);

  const [insuranceActive, setInsuranceActive] = useState(false);
  const [insuranceItem, setInsuranceItem] = useState(null);
  const [insurancePlan, setInsurancePlan] = useState(null);
  const [myselfPay, setMyselfPay] = useState(false);

  const [range, setRange] = useState({
    prev: 0,
    next: 4,
  });
  const [currentDates, setCurrentDates] = useState([]);

  const [appointmentFormData, setAppointmentFormData] = useState(null);
  const [openPaymentIframeModule, setOpenPaymentIframeModule] = useState(false);

  // @New Logic For Time Slots----------------------------------------------------
  const [apiTimeSlots, setApiTimeSlot] = useState([]);

  useEffect(() => {
    const parsedDate = generateTimeSlots(apiTimeSlots);
    setCurrentDates(parsedDate);
  }, [apiTimeSlots]);
  // -----------------------------------------------------------------------------

  const handleOpenModule = () => {
    setOpenModule((prevState) => !prevState);
  };

  const handleStateChange = (value) => {
    setStateValue(value);
  };

  const handleReason = (value) => {
    setReason(value);
  };

  const handlePatientChange = (value) => {
    setPatientValue(value);
  };

  const handleSetTimeslot = (slot, provider) => {
    setCurrentSlot({
      provider: provider,
      time: slot,
    });
  };

  const handlePrevDate = () => {
    const oldRange = { ...range };
    if (range.prev !== 0) {
      oldRange.prev = oldRange.prev - 5;
      oldRange.next = oldRange.next - 5;
      setRange(oldRange);

      // const newRange = getWeek(
      //   oldRange.prev,
      //   oldRange.next,
      //   stateValue?.timezone,
      // );

      // const newRange = getWeek(oldRange.prev, oldRange.next);
    }
  };

  const handleNextDate = () => {
    const oldRange = { ...range };
    if (range.next < 52) {
      oldRange.prev = oldRange.prev + 5;
      oldRange.next = oldRange.next + 5;
      setRange(oldRange);

      // const newRange = getWeek(
      //   oldRange.prev,
      //   oldRange.next,
      //   stateValue?.timezone,
      // );

      // const newRange = getWeek(oldRange.prev, oldRange.next);
    }
  };

  const handleInsuranceOpen = () => {
    setInsuranceActive(true);
    getInsurances({ state: stateValue?.name }).then((response) => {
      setInsurancesOptions(response);
    });
  };

  const handleInsuranceClose = () => {
    setInsuranceActive(false);
  };

  const handleInsuranceMenuClick = (menuItem) => () => {
    if (menuItem === 'myself') {
      setMyselfPay(true);
      setNotPlan(false);
      setInsuranceItem(null);
      setInsurancePlan(null);
    } else if (menuItem === 'not-plan') {
      setMyselfPay(false);
      setNotPlan(false);
      const find = insurancesOptions.filter((item) => item.id === 57);
      setInsuranceItem(find[0]);
    } else {
      setMyselfPay(false);
      setNotPlan(false);
      setInsuranceItem(menuItem);
    }
  };

  const handleInsuranceSubMenuClick = (menuItem) => () => {
    setInsurancePlan(menuItem);
    setInsuranceActive(false);
  };

  const handleClearAllInsurance = () => {
    setInsuranceItem(null);
    setInsurancePlan(null);
    setMyselfPay(false);
  };

  const handleOpenPaymentModule = () => {
    setOpenPaymentIframeModule((prevState) => !prevState);
  };

  useEffect(() => {
    getStates().then((response) => {
      setStatesOptions(response);
    });
    getVisitReasons().then((response) => {
      setVisitReasonssOptions(response);
    });
  }, []);

  useEffect(() => {
    if (appointmentFormData) handleOpenPaymentModule();
  }, [appointmentFormData]);

  const handlePagination = (_event, value) => {
    if (
      stateValue &&
      reason &&
      patientValue &&
      ((insuranceItem && insurancePlan) || myselfPay)
    ) {
      setLoading(true);
      getProviders({
        page: value,
        size: PAGE_SIZE,
        patient_status: patientValue?.name || null,
        insurance_carrier: insuranceItem?.name || null,
        insurance_plan: insurancePlan?.name || null,
        state__name: stateValue?.name || null,
        visit_reasons_seen__name: reason?.name || null,
        self_pay: myselfPay || null,
      }).then((response) => {
        setCurrentPage(value);
        setUpdate(false);
        setLoading(false);
        setProviders(response);
        setCurrentSlot({
          provider: null,
          time: null,
        });
      });
    }
  };

  const handleSearch = () => {
    const loadProviders = () => {
      setCurrentDates([]);
      setRange({
        prev: 0,
        next: 4,
      });
      setLoading(true);
      setProviders(null);
      getProviders({
        page: 1,
        size: PAGE_SIZE,
        patient_status: patientValue?.name || null,
        insurance_carrier: insuranceItem?.name || null,
        insurance_plan: insurancePlan?.name || null,
        state__name: stateValue?.name || null,
        visit_reasons_seen__name: reason?.name || null,
        self_pay: myselfPay || null,
      }).then((response) => {
        const data = response?.results;
        if (data) {
          const firstItem = data[0];
          const timeSlots = firstItem?.time_slots;
          setApiTimeSlot(timeSlots);
        }

        setCurrentPage(1);
        setProviders(response);
        setCurrentSlot({
          provider: null,
          time: null,
        });
        setLoading(false);
      });
    };

    if (
      stateValue &&
      reason &&
      patientValue &&
      ((insuranceItem && insurancePlan) || myselfPay)
    )
      loadProviders();
  };

  useEffect(() => {
    if (update) {
      getProviders({
        page: 1,
        size: PAGE_SIZE,
        patient_status: patientValue?.name || null,
        insurance_carrier: insuranceItem?.name || null,
        insurance_plan: insurancePlan?.name || null,
        state__name: stateValue?.name || null,
        visit_reasons_seen__name: reason?.name || null,
        self_pay: myselfPay || null,
      }).then((response) => {
        const data = response?.results;
        if (data) {
          const firstItem = data[0];
          const timeSlots = firstItem?.time_slots;
          setApiTimeSlot(timeSlots);
        }

        setCurrentPage(1);
        setUpdate(false);
        setProviders(response);
        setCurrentSlot({
          provider: null,
          time: null,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, currentPage]);

  const renderProviders = (
    <div className="desktop-providers">
      <BaseContainer>
        {providers !== null &&
          providers?.results?.map((provider) => (
            <Card
              data={provider}
              key={provider?.id}
              range={range}
              onOpenModule={handleOpenModule}
              setTimeSlot={handleSetTimeslot}
            />
          ))}
        {(providers === null || providers?.length === 0) && !loading && (
          <EmptyBox />
        )}
      </BaseContainer>
    </div>
  );

  return (
    <div>
      <Header>
        <Flexbox
          gap="10px"
          maxWidth="1200px"
          justifyContent="center"
          m="0 auto"
        >
          <LightBaseSelect
            options={statesOptions}
            label="Location"
            value={stateValue || ''}
            onChange={handleStateChange}
          />
          <LightBaseSelect
            options={visitReasonsOptions}
            label="Visit Reason"
            value={reason || ''}
            onChange={handleReason}
          />
          <LightBaseSelect
            options={PATIENT_STATUS}
            label="Patient Status"
            value={patientValue || ''}
            onChange={handlePatientChange}
          />
          <InsuranceMenu
            placeholder="Choose insurance or self-pay"
            onOpen={handleInsuranceOpen}
            onClose={handleInsuranceClose}
            active={insuranceActive}
            options={insurancesOptions}
            onMenuClick={handleInsuranceMenuClick}
            activeMenuItem={insuranceItem}
            onSubMenuClick={handleInsuranceSubMenuClick}
            activeSubMenuItem={insurancePlan}
            onClearAll={handleClearAllInsurance}
            isCustomItem={myselfPay}
            notPlan={notPlan}
          />
          {!matchesTablet && <SearchButton onClick={handleSearch} />}
        </Flexbox>
        {matchesTablet && <SearchButton onClick={handleSearch} />}
      </Header>

      <div className="week_view">
        <BaseContainer>
          {currentDates.length > 0 && (
            <WeekView
              currentDates={currentDates}
              onNextDate={handleNextDate}
              onPrevDate={handlePrevDate}
              range={range}
              count={providers?.count}
            />
          )}
          {loading && <ScreenLoader fullScreen={false} />}
        </BaseContainer>
      </div>

      {matchesMobile && !loading && renderProviders}
      {!matchesMobile && !loading && renderProviders}
      {providers !== null && (
        <ProviderPagination
          currentPage={currentPage}
          onPaginationChange={handlePagination}
          totalCount={providers?.count}
          pageSize={PAGE_SIZE}
        />
      )}

      <AppointmentModule
        visitReasons={reason}
        stateName={stateValue}
        patientStatus={patientValue}
        onClose={handleOpenModule}
        open={openModule}
        insuranceCarrier={insuranceItem}
        insurancePlan={insurancePlan}
        data={currentSlot}
        selfPay={myselfPay}
        insOptions={insurancesOptions}
        setAppointmentFormData={setAppointmentFormData}
        _apiUpdate={setUpdate}
      />

      <PaymentModule
        onCloseAppointment={handleOpenModule}
        location={stateValue}
        open={openPaymentIframeModule}
        onClose={handleOpenPaymentModule}
        apppointment={appointmentFormData}
        data={currentSlot}
        _apiUpdate={setUpdate}
      />

      <Toaster />
    </div>
  );
};

export default App;
